/*-----------------------------
    VARIABLE DE COULEUR DU THEME
-------------------------------*/

$couleur_principale: #71CEEE;
$couleur_texte_courant: #555;
$couleur_bordures: #ddd; // Pas celles des titres !
$couleur_fond_body: #fff;
$couleur_titre: #555; // Couleur des titres et des liserets sous les titres s'il y en a
$couleur_fond_contenu: #fff;
$couleur_fond_boutique: #fff;
$couleur_texte_blanc: #fff; // Pour les texte sur fond noir, overlay etc..
$couleur_fond_boutons: #555;
$couleur_texte_boutons: #fff;

/*-----------------------------
      GENERAL
-------------------------------*/

.mask {
    > h3, .h3 {
        color: $couleur_texte_blanc;
    }

    .addcommentaires {
        color: $couleur_texte_blanc;
    }
}

.content #fiche-produit .remise-produit {
    background: none;
}

.inmap .adresse a, .inmap .adresse, .inmap .adresse p {
    color: $couleur_texte_blanc;
}

.button:not(.zoom), .produits .addbasket, .addmsglo {
    color: $couleur_texte_boutons;
    background: $couleur_fond_boutons;
    &:hover, &:focus {
        background: darken($couleur_fond_boutons, 20%);
        color: $couleur_texte_boutons;
    }
}

body {
    color: $couleur_texte_courant;
    background: $couleur_fond_body;
}

.title_section, h2, .h2 {
    color: $couleur_titre;
    a {
        color: inherit;
    }
}

.content .page, .album-photo, .boutique, .mode-boutique {
    &::after{
        border-top-color: $couleur_principale;
        background-color: $couleur_bordures;
    }
}

.roundedgraymsg{
    border-color:$couleur_principale;
}

a {
    color: $couleur_principale;
}

.message_lo {
    border-bottom-color: $couleur_principale;

    .web a {
        color: $couleur_principale;
    }

    .note {
        border-color: $couleur_principale;
        color: $couleur_principale;
    }
}

.description {
    border-bottom-color: $couleur_principale;
}

.content #fiche-produit .remise-produit {
    color: $couleur_texte_blanc;
}

.form input {
    background: none repeat scroll 0 0 #DDDDDD;
}

#fiche-produit .remise-produit {
    color: $couleur_texte_blanc;
}

.contact_text {
    border-color: $couleur_principale;
}

.background-adr {
    background: none;
}

h2::after,
.h2::after,
.h2After,
.title_section::after{
    background-color: $couleur_bordures;
    border-top: 1px solid $couleur_principale;
}

/*-----------------------------
    HEADER
-------------------------------*/

.content_p {
    background: $couleur_fond_contenu;
    .content_p{
        background: transparent;
    }
}

.slogan {
    color: $couleur_texte_courant;
}

#headercontent {
    background: transparent;
}

/*-----------------------------
    NAVIGATION
-------------------------------*/

nav#nav-principal{
    .navigation_button_p{
        background: $couleur_texte_blanc;
        color:$couleur_principale;
        > .navigation_link_p{
            color:$couleur_principale;
        }
        &:focus, &:hover, &.actif, &.sousmenu_ouvert{
            color: $couleur_texte_blanc;
            background: none repeat scroll 0 0 $couleur_principale;
            > .navigation_link_p{
                color: $couleur_texte_blanc;
            }
        }
    }
    .sous_navigation_button_p{
        background: $couleur_principale;
        color: $couleur_texte_blanc;
        > .sous_navigation_link_p{
            color: $couleur_texte_blanc;
        }
        &:focus, &:hover, &.actif, &.sousmenu_ouvert{
            color: $couleur_principale;
            background: $couleur_texte_blanc;
            > .sous_navigation_link_p{
                color: $couleur_principale;
            }
        }
    }
    ul ul{
        box-shadow: 2px 3px 3px $couleur_bordures;
    }
}

nav.arianne ul li {

    &:first-child a:after {
        border-left-color: $couleur_bordures;
    }

    &:nth-child(2) a:before {
        border-left-color: $couleur_fond_contenu;
    }

    a {
        background: none repeat scroll 0 0 $couleur_bordures;
        color: $couleur_titre;

        &:hover {
            color: darken($couleur_titre, 20%);
        }
    }
}

/*-----------------------------
    PANIERS
-------------------------------*/

.template-panier {

    &.template-panier-2 {

        #header-panier {
            color: $couleur_principale;

            .count-live {
                color: $couleur_texte_blanc;
                background: $couleur_principale;
            }

            &:hover {
                background: $couleur_principale;
                color: $couleur_texte_blanc;

                .count-live {
                    background: $couleur_texte_blanc;
                    color: $couleur_principale;
                }
            }
        }

        #paniercontent {
            border-color: $couleur_principale;
        }
    }

    &.template-panier-3 {

        .bloc-connexion-panier {
            color: $couleur_principale;
            background: $couleur_fond_contenu;
        }

        .connexion p {
            color: $couleur_principale;

            a {
                color: $couleur_principale;
            }
        }

        #header-panier {
            color: $couleur_principale;

            .count-live {
                background: $couleur_principale;
                color: $couleur_texte_blanc;
            }
        }

        #paniercontent {
            border: 2px solid $couleur_principale;
        }
    }
}

/*-----------------------------
    SLIDERS
-------------------------------*/

.accueil_diaporama_template {

    a.bx-prev {
        background: $couleur_fond_boutons;
        color: $couleur_texte_blanc;

        &:hover {
            background: darken($couleur_fond_boutons, 20%);
        }
    }

    a.bx-next {
        background: $couleur_fond_boutons;
        color: $couleur_texte_blanc;

        &:hover {
            background: darken($couleur_fond_boutons, 20%);
        }
    }

    .slide .slide-text .content-slide-text {

        > p {
            background: rgba(0, 0, 0, 0.7);
            color: $couleur_texte_blanc;
        }

        > a.addcommentaires {
            background: rgba(0, 0, 0, 0.7);
            color: $couleur_texte_blanc;
        }
    }

    &.accueil_diaporama_template_3 #bx-pager {
        background: $couleur_bordures;
    }
}

/*-----------------------------
   ALBUMS PHOTO
-------------------------------*/

.template_album {

    .galerie .commentP {
        color: $couleur_texte_blanc;
    }

    .center-img::after {
        color: $couleur_texte_blanc;
    }

    .mask {
        background: #000;
    }
}

/*-----------------------------
    LIVRE D'OR
-------------------------------*/

.template_livreor {

    &.template_livreor_1 .message_lo.border_p {
        border: 1px solid $couleur_bordures;
        background-color: $couleur_fond_contenu;
    }

    &.template_livreor_2 {

        .IDLO {
            background: $couleur_principale;
            color: $couleur_texte_blanc;
            border-left: 2px solid rgba(0, 0, 0, 0.2);
        }

        .contenuMsgLO {
            background: $couleur_fond_boutique;

            .blcFleche::before {
                background: $couleur_fond_boutique;
            }

            .message {
                background: none;
            }
        }

        .message_lo:nth-child(odd) .contenuMsgLO {
            background: $couleur_fond_boutique;

            .blcFleche::before {
                background: $couleur_fond_boutique;
            }
        }
    }

    &.template_livreor_3 {

        .message_lo.border_p {
            background: $couleur_texte_blanc;
            border: 1px solid $couleur_bordures;
        }

        .message {
            &::before {
                color: $couleur_principale;
            }

            &::after {
                color: $couleur_principale;
            }
        }
    }
}

/*-----------------------------
    BOUTIQUE
-------------------------------*/

.remiseAfter{
    background-color:$couleur_principale;
}

.template_boutique_all {

    .produits {
        border: 1px solid $couleur_bordures;
        background: $couleur_fond_boutique;

        &:hover {
            box-shadow: 0 0 5px $couleur_bordures;
        }

        .remise::before {
            border-top-color: $couleur_principale;
        }

    }

    &.template_boutique_accueil {

        .remise::before {
            border-top-color: $couleur_principale;
        }

        &.accueil_boutique_template_1 {

            &:hover .bx-controls-direction {

                a.bx-prev {
                    background: $couleur_fond_boutons;
                    color: $couleur_texte_blanc;

                    &:hover {
                        background: darken($couleur_fond_boutons, 20%);
                    }
                }

                a.bx-next {
                    background: $couleur_fond_boutons;
                    color: $couleur_texte_blanc;

                    &:hover {
                        background: darken($couleur_fond_boutons, 20%);
                    }
                }
            }
        }

        &.accueil_boutique_template_2 .remise::before {
            border-top-color: $couleur_principale;
        }
    }

    &.template_boutique.template_boutique_5 .produits .remise::before {
        border-top-color: $couleur_principale;
    }
}

.produit_etiquette {
    background: $couleur_principale;
    .h3 {
        color: $couleur_fond_contenu;
    }

    &::after {
        border-color: $couleur_principale rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);
    }
}

.produit_etiquette2 {
    background: $couleur_principale;
    .h3 {
        color: $couleur_fond_contenu;
    }

    &::after {
        border-color: rgba(0, 0, 0, 0) $couleur_principale rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    }
}

.produits {

    .prixprod {
        color: $couleur_texte_courant;
    }

    .oldprix {
        color: $couleur_texte_courant;
    }

    .desc {
        color: $couleur_texte_courant;
    }

    .remise {
        color: $couleur_texte_blanc;
    }

    .nomprod {
        color: $couleur_principale;
    }

    select {
        border-color: $couleur_principale;
    }

    .addbasket, .addmsglo, .button:not(.zoom) {
        background: none repeat scroll 0 0 $couleur_fond_boutons;
    }

    .addbasket, .addmsglo, .button:not(.zoom) {
        &:hover, &:focus {
            background: darken($couleur_fond_boutons, 20%);
        }
    }
}

/*-----------------------------
    FICHE PRODUIT
-------------------------------*/

.template_fiche_produit {

    .wrap-details h4::after,
    .wrap-details .h4::after {
        background: $couleur_titre;
    }

    #fiche-produit {

        .triangle-ficheprod .remise-produit::before {
            border-top-color: $couleur_principale;
        }

        .wrap-description {

            .radio_unique {
                background: $couleur_fond_boutons;
                color: $couleur_texte_blanc;
                border-color: $couleur_fond_boutons;

                &:hover {
                    border-color: darken($couleur_fond_boutons, 20%);
                    background: darken($couleur_fond_boutons, 20%);
                }
            }

            .radio_color {
                border: 3px solid $couleur_bordures;

                &.radio-color-checked {
                    border-color: $couleur_fond_boutons;
                }

                &:hover {
                    border-color: $couleur_fond_boutons;
                }
            }

            .prix {
                border-color: $couleur_principale;

                .sociaux {
                    border-color: $couleur_principale;
                }
            }

            .wrap-details h4::after,
            .wrap-details .h4::after {
                background: $couleur_titre;
            }
        }
    }

    &.template_fiche_produit_1 #fiche-produit .productImageWrap {
        border: 1px solid $couleur_principale;
    }

    &.template_fiche_produit_2 #fiche-produit .wrap-images .productImageWrap {
        border: 1px solid $couleur_bordures;
    }

    &.template_fiche_produit_3 #fiche-produit {

        .wrap-images {
            border: 1px solid $couleur_bordures;
        }

        .in-onglet {
            border-left: 1px solid $couleur_bordures;
            border-right: 1px solid $couleur_bordures;
            border-bottom: 1px solid $couleur_bordures;
        }
    }
}

/*-----------------------------
    CONTACT
-------------------------------*/

form, .template_contact form, #fiche-produit form{
    .form-group{
        .formError ~ {
            input[type="text"]:not(.button):not([type="file"]), textarea, input[type="password"],select{
                border-color:red !important;
            }
        }
        input[type="text"]:not(.button):not([type="file"]), textarea, input[type="password"],select{
            border-width: 1px;
            border-style:dashed;
            border-color:$couleur_principale;
            background:$couleur_fond_contenu;
            color:$couleur_texte_courant;
            box-shadow: none;
        }
        input[type="text"]:not(.button):not([type="file"]).hasDatepicker{
            border-width: 1px;
        }
        .groupe-radio, .groupe-checkbox {
            &:hover{
                label {
                    &::before {
                        border-color:$couleur_principale;
                    }
                }
            }
            input:checked + label::before{
                border-color:$couleur_principale;
                background-color:$couleur_principale;
            }
        }
        textarea{
            border-width:1px;
        }
    }
}

/*-----------------------------
    MEDIA QUERIES
-------------------------------*/

@media screen and (max-width: 1100px) {

    .template-panier.template-panier-2 {
        #header-panier {
            background: $couleur_principale;
            color: $couleur_texte_blanc;

            .count-live {
                color: $couleur_principale;
                background: #fff;
            }
        }
    }

    header .header-conteneur .menu {
        nav#nav-principal {

            .actif .dropDown {
                color: $couleur_texte_blanc;
            }

            .dropDown {
                color: $couleur_principale;
            }

            .menu_burger {
                background: $couleur_principale;
                color: $couleur_texte_blanc;
            }
        }
    }

    .menu_burger {
        background-color: $couleur_fond_contenu;
    }

    .menu_burger + ul {
        background-color: $couleur_fond_contenu;
    }

    nav#nav-principal > ul > li {
        border-bottom: 1px solid $couleur_bordures;
    }
}

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: $couleur_principale;
    border-color: $couleur_principale;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: $couleur_principale;
    color: $couleur_principale;

    a, span {
        color: $couleur_principale;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}